<script setup lang='ts'>
import { useAppDataStore } from '@/stores/appdata';

const route = useRoute();
const store = useAppDataStore();
const user = useUser();

const parseSlug = async () => {
    const slugParts = route.params.slug || [];

    const categoryId = Number(slugParts[1]) || 0;

    if (!categoryId) {
        // reset the category id and name cos we're dealing with a new area
        store.setCategoryId(null);
        store.setCategoryName('');

        await store.loadCategories();
        await navigateTo(`/${store.categories[0].slug}/${store.categories[0].id}`, { replace: true });
        parseSlug();

        return;
    } else if (store.categories.length === 0) {
        await store.loadCategories();
    }

    if (store.categoryId !== categoryId) {
        store.setCategoryName(slugParts[0] || '');
        store.setCategoryId(Number(slugParts[1]) || 0);
    }
};

onMounted(() => {
    parseSlug();
});

watch(() => route.params.slug, parseSlug, { deep: true });

</script>

<template>
    <div class="layout-main-wrapper font-primary font-normal flex flex-col flex-1" :class="[user && 'pb-20 md:pb-0']">
        <AppTopbar class="" />
        <div class="layout-main-container w-full pr-0 pl-0">
            <div class="layout-main h-full">
                <slot />
            </div>
        </div>

        <AppFooter class="hidden md:block" />
    </div>
</template>

<style lang="scss" scoped>

</style>
